<template>
  <div class="mx-2">
    <div class="row">
      <div class="col-12 col-md-5">
        <div class="form-group">
          <label for="sku" class="d-block">
            Поиск товара
          </label>
          <div>
            <input
                v-model="form.prices.searchQuery"
                @change="getPrices"
                class="form-control"
                placeholder="Поиск..."
            />
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <label for="sku" class="d-block">
            Цена (%)
            <feather-icon icon="HelpCircleIcon" id="discount-price-label"/>
            <b-tooltip title="скидка от базовой цены" target="discount-price-label"/>
          </label>
          <div>
            <input
                id="sku"
                :disabled="!form.prices.price_activation"
                v-model="form.prices.discount_price"
                placeholder="Введите %"
                class="form-control"
            >
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <label for="sku" class="d-block">
            Старая цена (%)
            <feather-icon icon="HelpCircleIcon" id="old-price-label"/>
            <b-tooltip title="% наценка от базовой цены" target="old-price-label"/>
          </label>
          <div>
            <input
                id="sku"
                :disabled="!form.prices.price_activation"
                v-model="form.prices.old_price"
                placeholder="Введите %"
                class="form-control"
            >
          </div>
        </div>
      </div>
      <div class="col-1">
        <b-form-group label="Активно" label-cols="10" class="font-small-1">
          <b-form-checkbox
              v-model="form.prices.price_activation"
              class="mr-0"
              name="is-rtl"
              switch
              inline
          />
        </b-form-group>
      </div>
      <div class="col-2 mt-2">
        <button class="btn btn-primary" @click="updatePriceSettings">Сохранить</button>
      </div>
    </div>
    <div class="mx-2 mb-2">
      <div class="row">
        <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">
            Показано с {{ prices.from }} по {{ prices.to }} из {{ prices.total }} записей
          </span>
        </div>
        <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
              v-model="form.prices.currentPage"
              :total-rows="prices.total"
              :per-page="prices.per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
    <div class="position-relative table-responsive">
      <table class="table b-table">
        <thead>
        <tr>
          <th>#</th>
          <th>Фото</th>
          <th>Название</th>
          <th>Артикул</th>
          <th>Количество в наличии</th>
          <th>Мой Вход</th>
          <th>Розничная Цена</th>
          <th>Розничная Цена Без скидки</th>
          <th>Ручная Цена</th>
          <th colspan="2">Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="specification in prices.data" :key="specification.id">
          <td>{{ specification.id }}</td>
          <td>
            <img
                v-if="specification.small_main_image"
                :src="`/img/product/${specification.small_main_image}`"
                class="product-img" alt=""
            >
          </td>
          <td>
            <router-link :to="{ name: 'product-details', params: { id: specification.product.id } }">
              {{ specification.product.name }} {{ specification.product.brand_name }}
            </router-link>
            <br/>
            <small>{{ specification.product.category.path }} > {{ specification.product.category.name }}</small>
            <br/>
            <small v-if="specification.size_name">Размер: {{ specification.size_name }}</small>

          </td>
          <td>{{ specification.product.sku }}</td>
          <td>{{ specification.qty }}</td>
          <td class="text-nowrap">
            <span class="amount warning">{{ specification.user_prices.user_discount_price }}</span>
            <span class="currency"> грн</span>
          </td>
          <td class="text-nowrap">
            <span class="amount positive">{{ specification.user_prices.retail_discount_price }}</span>
            <span class="currency"> грн</span>
          </td>
          <td class="text-nowrap">
            <span class="amount positive">{{ specification.user_prices.retail_price }}</span>
            <span class="currency"> грн</span>
          </td>
          <td v-if="specification.external_price" class="text-nowrap">
            <span class="amount positive">
              {{ specification.external_price.amount | toPretty }}
            </span>
            <span class="currency"> грн</span>
          </td>
          <td v-else>-</td>
          <td>
            <button @click="openEditModal(specification.id)" class="btn btn-primary btn-sm">Изменить</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="mx-2 mb-2">
      <div class="row">
        <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">
            Показано с {{ prices.from }} по {{ prices.to }} из {{ prices.total }} записей
          </span>
        </div>
        <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
              v-model="form.prices.currentPage"
              :total-rows="prices.total"
              :per-page="prices.per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
    <PriceEditModal
        :specification-id="selectedSpecificationId"
        :external-service-id="externalServiceId"
        @update="getPrices"
    />
  </div>
</template>

<script>
import {
  BFormCheckbox, BFormGroup, BPagination, BTooltip,
} from 'bootstrap-vue';
import PriceEditModal from '@/views/automation/PriceEditModal.vue';
import { toPretty } from '@/helpers/formatters';

export default {
  name: 'ExternalServiceConfiguration',
  props: ['externalServiceId', 'prefix'],
  components: {
    BTooltip,
    BFormCheckbox,
BFormGroup,
BPagination,
PriceEditModal,
  },
  data: () => ({

    form: {
      prices: {
        discount_price: null,
        old_price: null,
        price_activation: false,
        searchQuery: null,
        currentPage: 1,
      },
    },
    prices: {
      current_page: 1,
      data: [],
      from: null,
      to: null,
      total: null,
      per_page: 10,
    },
    selectedSpecificationId: null,
  }),
  watch: {
    'form.prices.currentPage': {
      async handler(newValue) {
        this.prices.current_page = newValue;
        await this.getPrices();
      },
    },
    externalServiceId: {
      async handler() {
        await this.getPrices();
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.getConfiguration();
    await this.getPrices();
  },
  methods: {
    async getConfiguration() {
      const configuration = (await this.$api.external.configuration.info(this.externalServiceId)).data;
      this.form.prices.discount_price = toPretty(configuration.price_discount_percent);
      this.form.prices.old_price = toPretty(configuration.old_price_percent);
      this.form.prices.price_activation = configuration.price_management === 1;
    },
    toPretty,
    openEditModal(id) {
      this.selectedSpecificationId = id;
      this.$bvModal.show('modal-price-edit');
    },
    async getPrices() {
      this.prices = (await this.$api.external.prices.list(this.externalServiceId, {
        ...(this.prices.current_page > 1 && { page: this.prices.current_page }),
        limit: 25,
        ...(this.form.prices.searchQuery && { search: this.form.prices.searchQuery }),
      })).data;
    },
    async updatePriceSettings() {
      if (!this.form.prices.price_activation) {
        this.form.prices.discount_price = null;
        this.form.prices.old_price = null;
      }

      if (this.form.prices.price_activation
          && (this.form.prices.discount_price === '' || this.form.prices.old_price === '')) {
        this.$bvToast.toast('Скидки на цены должны быть не пустыми', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });

        return;
      }

      try {
        const data = {
          price_management: this.form.prices.price_activation ? 1 : 0,
          price_discount_percent: this.form.prices.discount_price * 100,
          old_price_percent: this.form.prices.old_price * 100,
        };
        await this.$api.external.configuration.priceManagement(this.externalServiceId, data);
        await this.getConfiguration();
        await this.getPrices();
        this.$bvToast.toast('Управление ценами изменено', {
          title: 'Успех',
          variant: 'success',
          solid: true,
        });
      } catch (e) {
        this.$bvToast.toast('Ошибка при сохранении настроек', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import '~@core/scss/base/bootstrap-extended/include';

.product-img {
  max-height: 80px;
}

span.amount {
  font-size: 18px;
}
.positive {
  color: $success;
}
.warning {
  color: $warning;
}
.negative {
  color: $danger;
}
span.currency {
  font-size: 10px;
}
</style>
