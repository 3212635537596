<template>
  <b-modal
      id="modal-price-edit"
      :title="`Редактирование цены товара #${specificationId} на Rozetka`"
      ok-title="Сохранить"
      @ok="update"
      cancel-title="Отмена"
      cancel-variant="outline-secondary"
  >
    <div class="form-group">
      <label>Новая цена</label>
      <input class="form-control" v-model="form.amount">
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import { toInteger } from '@/helpers/formatters';

export default {
  name: 'PriceEditModal',
  props: ['specificationId', 'externalServiceId'],
  components: { BModal },
  data() {
    return {
      form: {
        amount: null,
      },
    };
  },
  methods: {
    async update() {
      await this.$api.external.prices.update(this.externalServiceId, {
        specification_id: this.specificationId,
        amount: toInteger(this.form.amount),
      });
      this.$emit('update');
    },
  },
};
</script>

<style scoped>

</style>
